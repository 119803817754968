import { AbstractEntity } from "../abstract-entity";
import { SimpleEntity } from "../simple-entity";

export class User extends AbstractEntity {
    userTag?: string;
    override name: string ='';
    firstName?: string;
    surname?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    authority?: SimpleEntity;
    userSettings?: SimpleEntity;
    birthdate?: Date;
    lastLoginTime?: Date;
    address?: SimpleEntity[];
    identityNo?: string;
    photoUrl?: string;
    isPhoneVerified?: boolean;
    isEmailVerified?: boolean;
    isAcceptedKVKK?: boolean;
    isActive?: boolean;
}
