import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/core/utils/routing.service';
import { User } from 'src/app/model/entity/auth/user';
import { LoginService } from 'src/app/services/auth/login/login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  loggedUser? : User = new User;
  constructor(private router: Router, private routingService: RoutingService, private authService: LoginService) {
  }

  ngOnInit(): void {
    this.loggedUser = this.authService.currentUser();
  }
}
