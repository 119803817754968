import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition(':enter', [
        animate('500ms ease-in', style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class HomeComponent {

  showNotification: boolean = false;

  hideNotification() {
    this.showNotification = false;
  }

  constructor(private router: Router) {}

  redirectToApp() {
    window.location.href = 'https://app.blocktemp.email/auth/login';
  }

  showInfoNotification() {
    this.showNotification = true;
    setTimeout(() => {
      if(this.showNotification){
        this.showNotification = false;
      }
     
    }, 7000);
  }
}
