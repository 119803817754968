import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FastLoginRequestModel } from 'src/app/model/entity/auth/fast-login-request-model';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router:Router,private _location: Location) { }

  goHome(){
    this.router.navigate(['/']);
  }

  go404(){
    this.router.navigate(['/404']);
  }

  goSignIn(){
    this.router.navigate(['/sign-in']);
  }

  goForgotPassword(){
    this.router.navigate(['/forgot-password']);

  }

  goOffers(){
    this.router.navigate(['/']);
  }

  goCreateOffer(){
    this.router.navigate(['/offer-create']);
  }
  goFastSignIn(){
    this.router.navigate(['/fast-sign-in']);
  }

  goOfferDetail(offerID: number){
    this.router.navigate(['/offer-detail/'.concat(offerID.toString())]);
  }

  goProductDetail(productID: number){
    this.router.navigate(['/product-detail/'.concat(productID.toString())]);
  }

  goVerificationWaiting(entity:FastLoginRequestModel){
    const navigationExtras: NavigationExtras = {
      state: {
        model: entity
      }
    };
    
    this.router.navigate(['/verification-waiting'],navigationExtras);
  }

  goBack(){
    this._location.back();
  }
}
