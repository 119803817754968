<div class="wrapper">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light nav-white bg-light fixed-top">
        <div class="container">
          <a class="navbar-brand" href="#"><img src="assets/icons/logo-with-black.png" width="220" alt="Logo"> </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto navbar-right">
              <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#main">Home</a></li>
              <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#features">About</a></li>
              <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Features</a></li>
              <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#pricing">Pricing</a></li>
              <!--<li class="nav-item"><a class="nav-link js-scroll-trigger" href="sign-in">Log In</a></li>-->
              <li class="nav-item" style="cursor: pointer;"><a class="nav-link js-scroll-trigger" (click)="redirectToApp()">Log In</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    <div class="notification-popup" *ngIf="showNotification" @fadeInOut>
      <div class="notification-content">
        <img src="assets/icons/info-icon-ntfcn.png" alt="Notification Icon" class="circular-icon">
        <div class="notification-text">
          <div class="notification-header">
            <p>Very Soon</p>
          </div>
          <div class="notification-message">
            <p>This is a just demo, stay tuned for full version.</p>
          </div>
        </div>
        <!--<div class="notification-close" (click)="hideNotification()">ˣ</div>-->
      </div>
    </div>
    



    <div id="main" class="main">
      <div class=" home-3">
        <div class="container">
          <div class="hero-inner">
            <div class="intro-block wow fadeInLeft">
              <h1>Don't let temp mail 
                <b style="color: #79a4c8;">abuse</b>
                 on your systems</h1>
              <p>Protect your systems from temporary mail abuse with our API. Say goodbye to spam and ensure the integrity of your data. Try the demo now.</p>
              <ul class="checklist">
                <li><span class="check">&#10004;</span> Simple integration with one API</li>
                <li><span class="check">&#10004;</span> No more temp mail registrations</li>
                <li><span class="check">&#10004;</span> No longer provide promotions to fake users.</li>
              </ul>
              <a href="/dashboard" class="btn btn-action btn-edge wow fadeInLeft" data-wow-delay="0.2s">Start Free Trial</a>
            </div>
            <div class="hero-img wow fadeInUp">
              <img src="assets/images/main-xl.gif" height="550px" alt="Hero Image">
            </div>
          </div>
        </div>
      </div>


      <div class="yd-ft">
        <div class="containr">
          <div class="yd-ft-inner">
            <div class="yd-ft-left wow fadeInLeft">
              <h2>Simple JSON API </h2>
                  <p>Our API is dead simple to use. Just pass an email and your API token and get the validation result as a JSON object.</p>
                 
            </div>
            <div class="yd-ft-right wow fadeInLeft">

                <div class="card">
                    <div class="card-header">
                        Example API Calls
                    </div>
                    <div class="card-body">
                       <p>address@gmail.com<span class="badge badge-success mr-l5">Okay</span></p>
                        <div class="card-inner" style="background-color: #2d2d2d; border-radius: 10px;">
                            <div class="inner-card-body">
                                <code class="language-javascript">$ curl --location --globoff '<span class="token-string">https://api.creatorium.org/mailVerifier/verify/email?address=address@gmail.com</span>'
                                    <span class="token-punctuation"> \</span> --header '<span class="token-string">X-API-KEY:</span><span class="token-property">YOUR-API-KEY</span>'
                                </code>
                            </div>
                            <div class="response-card-body inner-card-body">
                                <p class="language-javascript">Response Body</p>
                                <code class="language-javascript">
                                    {{'{'}}
                                    <br><span class="json-operator">"mx"</span>:<span class="json-string">"mail.google.com"</span>
                                    <br><span class="json-operator">"domain"</span>:<span class="json-string">"google.com"</span>
                                    <br><span class="json-operator">"block"</span>:<span class="json-string">"NO"</span>
                                    <br><span class="json-operator">"disposable"</span>:<span class="json-string">"NO"</span>
                                    <br><span class="json-operator">"reason"</span>:<span class="json-string">"Whitelisted"</span>
                                    <br>{{'}'}}
                                </code>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <p>address@tempmail.com<span class="badge badge-danger mr-l5">Blocked</span></p>
                         <div class="card-inner" style="background-color: #2d2d2d; border-radius: 10px;">
                             <div class="inner-card-body">
                                 <code class="language-javascript">$ curl --location --globoff '<span class="token-string">https://api.creatorium.org/mailVerifier/verify/email?address=address@tempmail.com</span>'
                                     <span class="token-punctuation"> \</span> --header '<span class="token-string">X-API-KEY:</span><span class="token-property">YOUR-API-KEY</span>'
                                 </code>
                             </div>
                             <div class="response-card-body inner-card-body">
                                 <p class="language-javascript">Response Body</p>
                                 <code class="language-javascript">
                                     {{'{'}}
                                     <br><span class="json-operator">"mx"</span>:<span class="json-string">"{{'{none}'}}"</span>
                                     <br><span class="json-operator">"domain"</span>:<span class="json-string">"tempmail.com"</span>
                                     <br><span class="json-operator">"block"</span>:<span class="json-string">"YES"</span>
                                     <br><span class="json-operator">"disposable"</span>:<span class="json-string">"YES"</span>
                                     <br><span class="json-operator">"reason"</span>:<span class="json-string">"SPF record include spam information."</span>
                                     <br>{{'}'}}
                                 </code>
                             </div>
                         </div>
                     </div>

                </div>

            </div>
          </div>
        </div>
      </div>


      <div id="services" class="lbl-services">
        <div class="container">
          <div class="row justify-center">
            <div class="col-md-8">
              <div class="service-intro wow fadeInDown text-center">
               <h1>Reach Real Users, Get Rid Of Fake Email Addresses</h1>
               <p>
                A disposable or a fake email looks like a real email address. Unfortunately, it is a throwaway address that is used once or twice and never again. So there will be no one reading all the marketing emails you send to that address.
              </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInDown" data-wow-delay="0.1s">
              <div class="lbl-service-card">
                <div class="card-icon">
                  <div class="card-img">
                    <img src="assets/icons/mail.png" width="50" alt="Feature">
                  </div>
                </div>
                <div class="card-text">
                  <h3>950 Addresses Detected</h3>
                  <p>to our block list in the last four weeks
                </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInDown" data-wow-delay="0.2s">
              <div class="lbl-service-card">
                <div class="card-icon">
                  <div class="card-img">
                    <img src="assets/icons/database.png" width="50" alt="Feature">
                  </div>
                </div>
                <div class="card-text">
                  <h3>150k+ Domains</h3>
                  <p>of disposable email providers in our database</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInDown" data-wow-delay="0.3s">
              <div class="lbl-service-card">
                <div class="card-icon">
                  <div class="card-img">
                    <img src="assets/icons/idea.png" width="50" alt="Feature">
                  </div>
                </div>
                <div class="card-text">
                  <h3>AI Supported Algorithm</h3>
                  <p>to detect is domain disposable</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>



        <div id="features" class="yd-features">
          <div class="yd-ft">
            <div class="yd-ft-inner wow fadeInDown">
              <div class="yd-ft-right">
                <img src="assets/images/technical.png" alt="">
              </div>
              <div class="yd-ft-left">
                <h2>Developer friendly solution</h2>
                    <p>Backed by a team of technical experts, blocktemp.email is dedicated to resolving all your technical inquiries. Depending on your subscription plan, you'll also gain exclusive access to direct assistance from our engineering team.</p>
                <!--<a href="#" class="wow fadeInLeft" data-wow-delay="0.2s">Learn more</a>-->
              </div>
            </div>
          </div>

          <div class="yd-ft">
            <div class="containr">
              <div class="yd-ft-inner wow fadeInDown">
                <div class="yd-ft-left">
                  <h2>Huge & Updated Database with AI Power </h2>
                      <p>Harnessing the power of cutting-edge AI technology, our extensive database boasts over 150,000 domains notorious for fake and temporary emails. Continuously updated, we vigilantly track new providers emerging daily, adding between 5 and 20 new listings on average. Our AI-driven algorithms meticulously analyze DNS records and spam indicators to accurately detect temporary addresses.</p>
                  <!--
                  <a href="#" class="wow fadeInLeft" data-wow-delay="0.2s">Learn More</a>
                  -->
                </div>
                <div class="yd-ft-right">
                  <img src="assets/images/big-data.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="yd-ft">
            <div class="yd-ft-inner wow fadeInDown">
              <div class="yd-ft-right">
                <img src="assets/images/pricing.png" alt="">
              </div>
              <div class="yd-ft-left">
                <h2>Fair Pricing Policy</h2>
                    <p>Discover the Power of blocktemp.email: Basic usage is free, with affordable premium plans. Enjoy 200 free monthly usages and special discounts for non-profits. Reach out to our <a href="mailto:hello@creatorium.com">support</a> for details.</p>
                <!--
                    <a href="#" class="wow fadeInLeft" data-wow-delay="0.2s">Learn more</a>
                -->
              </div>
            </div>
          </div>

        </div>


    




        <div id="reviews" class="yd-reviews">
          <div class="container">
            <div class="row text-center">
              <div class="col-sm-12 col-lg-8 offset-lg-2">
                <div class="intro wow fadeInDown" data-wow-delay="0.1s">
                  <h1>Users say nice things</h1>
                  <p>We have  very fair pricing policy that would benefit you and us at the same time.
                     Get the free plan &amp; if you need more - pay.
                  </p>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 text-center">
                <div class="review-card wow fadeInDown" data-wow-delay="0.1s">
                  <div class="review-text">
                    <h1>BlockTemp.Email is fantastic! I used to get so many fake sign-ups on my blog, but now everything is clean and real. Highly recommend it!
                    </h1>
                  </div>
                  <div class="review-attribution">
                    <div class="review-img">
                      <img class="img-fluid rounded-circle" src="assets/icons/reviewer-1.png" alt="Review">
                    </div>
                    <h2> - Marc</h2>
                    <h6></h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 text-center">
                <div class="review-card wow fadeInDown" data-wow-delay="0.2s">
                  <div class="review-text">
                    <h1>This service has been a lifesaver for my online store. No more dealing with spammy accounts. It's easy to use and super effective
                    </h1>
                  </div>
                  <div class="review-attribution">
                    <div class="review-img">
                      <img class="img-fluid rounded-circle" src="assets/icons/reviewer-3.png" alt="Review">
                    </div>
                    <h2> - Fredel</h2>
                    <h6></h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 d-none d-lg-block text-center">
                <div class="review-card wow fadeInDown" data-wow-delay="0.3s">
                  <div class="review-text">
                    <h1>I've been using BlockTemp.Email for a while now, and it's been great. It keeps my newsletter list clean and helps me connect with genuine subscribers.
                    </h1>
                  </div>
                  <div class="review-attribution">
                    <div class="review-img">
                      <img class="img-fluid rounded-circle" src="assets/icons/reviewer-2.png" alt="Review">
                    </div>
                    <h2> - Gokhan</h2>
                    <h6></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div id="pricing" class="pricing-section text-center">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="pricing-intro">
                  <h1 class="wow fadeInDown" data-wow-delay="0s">Our Pricing Plans.</h1>
                  <p class="wow fadeInDown" data-wow-delay="0.2s">
                    Our plans are designed to meet the requirements of both beginners <br class="hidden-xs"> and players.
                    Get the right plan that suits you.
                  </p>
                </div>
                <!--
                <div class="row">
                  <div class="col-md-4">
                    <div class="table-left wow fadeInDown" data-wow-delay="0.4s">
                      <div class="pricing-details">
                        <h2>Freemium</h2>
                        <span>Free</span>
                          <ul>
                            <li>&#10004; 200 API Calls / Month </li>
                            <li>✘ Management Dashboard</li>
                          <li>✘ Technical support if required.</li>
                          </ul>
                        <button class="btn btn-action" type="button">Get Plan</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="table-right table-center wow fadeInDown" data-wow-delay="0.6s">
                      <div class="pricing-details">
                        <h2>Beginner</h2>
                        <span class="text-green">$xxxx</span>
                        <ul>
                          <li>&#10004; 15,000 API Calls / Month </li>
                          <li>&#10004; Management Dashboard</li>
                          <li>✘ Technical support if required.</li>
                        </ul>
                        <button class="btn btn-action btn-green" type="button">Buy Now</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="table-right wow fadeInDown" data-wow-delay="0.6s">

                      <div class="pricing-details">
                        <h2>Premium</h2>
                        <span>$xxxx</span>
                        <ul>
                          <li>&#10004; 50,000 API Calls / Month </li>
                          <li>&#10004; Management Dashboard</li>
                          <li>&#10004; Technical support if required.</li>

                        </ul>
                        <button class="btn btn-action" type="button">Buy Now</button>
                      </div>
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
          <!--
          <span>
          You can always start on our free plan with 200 API requests/month.No credit card required. Read more about how our billing works.
        </span>
          -->
        <span>
          Plans will be right there soon. Stay tuned.
        </span>
        </div>


        <div id="buy" class="cta-sm">
          <div class="container-m text-center">
            <div class="cta-content wow fadeInDown">
              <h4>WHAT ARE YOU WAITING FOR?</h4>
              <h1>Start now and get rid of all fake users</h1>
              <a class="btn-cta" href="#">Get Started now</a>
            </div>
          </div>
        </div>


        <div class="footer-sm">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <a class="footer-logo" href="#">blocktemp.email</a>
              </div>
              <div class="col-md-4">
                <h6>&copy; Creatorium 2022 Rights Reserved</h6>
              </div>
              <!--
              <div class="col-md-4">
                <ul>
                  <li><a href="#">Facebook</a></li>
                  <li><a href="#">Twitter</a></li>
                  <li><a href="#">Linkedin</a></li>
                </ul>
              </div>
              -->
            </div>
          </div>
        </div>


      <!-- Scroll To Top -->
        <div id="back-top" class="bk-top">
          <div class="bk-top-txt">
            <a class="back-to-top js-scroll-trigger" href="#main" style="font-size: 30px;">↑</a>
           </div>
        </div>
      <!-- Scroll To Top Ends-->
    </div> <!-- Main -->
  </div><!-- Wrapper -->


  <!-- Jquery and Js Plugins -->
  <script src="assets/js/jquery-2.1.1.js"></script>
  <script src="assets/js/jquery.validate.min.js"></script>
  <script src="assets/js/popper.min.js"></script>
  <script src="assets/js/bootstrap.min.js"></script>
  <script src="assets/js/plugins.js"></script>
  <script src="assets/js/jquery.accordion.js"></script>
  <script src="assets/js/custom.js"></script>



  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
  <script src="https://kit.fontawesome.com/f442313f08.js" crossorigin="anonymous"></script>