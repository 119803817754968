import { Observable, of, tap } from "rxjs";
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpParameterCodec, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, Injector} from "@angular/core";
import Storage from 'src/app/model/framework/constants/storage';
import { Router } from "@angular/router";
import AppConfig from 'src/app/app-config';
import { CacheService } from "src/app/services/framework/cache.service";
import { NotificationService } from 'src/app/services/framework/notification.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    private tokenPrefix: string = "Bearer ";

    constructor(private injector: Injector, private cacheService: CacheService, private router: Router,private notificationService: NotificationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes("/public") && !req.url.includes("/auth")) {
            req = req.clone({
                setHeaders: {
                    Authorization: this.tokenPrefix.concat(this.cacheService.get(Storage.TOKEN))
                  }
                , withCredentials: true
            });
        }
        const params = new HttpParams({encoder: new CustomEncoder(), fromString: req.params.toString().replace("+", "%2B")});//UFUK : + isaretini encode edemiyor manuel encode ediyoruz
        req = req.clone({
          params,
          url: req.url
      });

      return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
        
      }, (error: any) => {
          this.handleError(error);
          return of(error);
      }));
    }

    private handleError(err: HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
            this.cacheService.remove(Storage.USER);
            this.cacheService.remove(Storage.TOKEN);
            this.router.navigate(["sign-in"]);
            this.notificationService.showWarning('Oturum süreniz dolduğu için yeniden giriş yapmanız gerekiyor.', 'Uyarı');
            return of(err.message);
        } else if (err.status === 400) {
            if (err.error == null) {
                throw err;
            } else {
                throw err;
            }
        } else if (err.status === 404) {
            if (err.error == null) {
                throw err;
            } else {
                throw err;
            }
        } else {
            throw err;
        }
    }


}

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
      return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
      return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
      return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
      return decodeURIComponent(value);
    }
  }
