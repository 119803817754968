import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/services/framework/notification.service';
import { RoutingService } from '../utils/routing.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorMessageHandler {

    constructor(private notificationService: NotificationService,private routingService : RoutingService){}

    ERROR_MESSAGES = [{
        key:'usernamePasswordFail',value: 'Kullanıcı adı ya da şifre hatalı!',
    },
    {
        key:'dataNotFound',value: 'Veri bulunamadı!',
    },
    {
        key:'somethingWrong',value: 'Bir şeyler ters gitti, lütfen daha sonra tekrar deneyin.',
    },
    {
        key:'accessDenied',value: 'Erişim reddedildi!',
    },
    {
        key:'pageNotFound',value: 'Sayfa bulunamadı.',
    }];


    handleError(err: any){
        if(err.error=='dataNotFound'){
            this.routingService.go404();
        }
        this.notificationService.showError(this.getErrorMessage(err.error), 'Hata!');
    }


    getErrorMessage(errorKey: string): string {
        return this.ERROR_MESSAGES.find(item => item.key === errorKey)?.value || 'undefined' ;
    }
}