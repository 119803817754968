<link href="https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap" rel="stylesheet">
<link
    href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
    rel="stylesheet">


    <div class="connect-container align-content-stretch d-flex flex-wrap">
        <div class="page-sidebar">
            <div class="logo-box"><a href="#" class="logo-text">blockTemp.email</a><a href="#" id="sidebar-close"><i class="material-icons">close</i></a> <a href="#" id="sidebar-state"><i class="material-icons">adjust</i><i class="material-icons compact-sidebar-icon">panorama_fish_eye</i></a></div>
            <div class="page-sidebar-inner slimscroll">
                <ul class="accordion-menu">
                    <li class="sidebar-title">
                        menu
                    </li>
                    <li class="active-page">
                        <a href="index.html" class="active"><i class="material-icons-outlined">dashboard</i>Dashboard</a>
                    </li>
                   
                    <li>
                        <a href="profile.html"><i class="material-icons-outlined">account_circle</i>Profile</a>
                    </li>
                   
                    <li class="sidebar-title">
                       Settings
                    </li>
                    <li>
                        <a href="profile.html"><i class="material-icons-outlined">account_circle</i>API Keys</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="page-container">
            <div class="page-header">
                <nav class="navbar navbar-expand">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <ul class="navbar-nav">
                        <li class="nav-item small-screens-sidebar-link">
                            <a href="#" class="nav-link"><i class="material-icons-outlined">menu</i></a>
                        </li>
                        <li class="nav-item nav-profile dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="../../assets/images/avatars/profile-image-1.png" alt="profile image">
                                <span>{{loggedUser?.firstName ==undefined ? 'New': loggedUser?.firstName}} {{loggedUser?.surname ==undefined ? 'User' : loggedUser?.surname }}</span>
                            </a>
                            
                        </li>
                       
                    </ul>
                    
                </nav>
            </div>
            <div class="page-content">
                <div class="page-info">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Main</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </nav>
                    <div class="page-options">
                        <a href="#" class="btn btn-secondary">Settings</a>
                        <a href="#" class="btn btn-primary">Upgrade</a>
                    </div>
                </div>
                <div class="main-wrapper">
                    <div class="row stats-row">
                        <div class="col-lg-4 col-md-12">
                            <div class="card card-transparent stats-card">
                                <div class="card-body">
                                    <div class="stats-info">
                                        <h5 class="card-title">12,350</h5>
                                        <p class="stats-text">Requests Remaining (17 days)</p>
                                    </div>
                                    <div class="stats-icon change-danger">
                                        <i class="material-icons">trending_down</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="card card-transparent stats-card">
                                <div class="card-body">
                                    <div class="stats-info">
                                        <h5 class="card-title">2,953</h5>
                                        <p class="stats-text">Temp Mail Detected</p>
                                    </div>
                                    <div class="stats-icon change-success">
                                        <i class="material-icons">trending_up</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="card card-transparent stats-card">
                                <div class="card-body">
                                    <div class="stats-info">
                                        <h5 class="card-title">300</h5>
                                        <p class="stats-text">Safe Mail Detected</p>
                                    </div>
                                    <div class="stats-icon change-success">
                                        <i class="material-icons">trending_up</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="alert alert-info no-m" role="alert">
                                    Data has been updated 35 minutes ago, go to the reports page to access data history.
                                </div>
                            </div>
                        </div>
                       -->
                        <div class="col-lg-12">
                            <div class="card card-transactions">
                                <div class="card-body">
                                    <h5 class="card-title">Transactions<a href="#" class="card-title-helper blockui-transactions"><i class="material-icons">refresh</i></a></h5>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0776</td>
                                                    <td>Sale Management</td>
                                                    <td>$18, 560</td>
                                                    <td><span class="badge badge-success">Finished</span></td>
                                                </tr>
                                                <tr>
                                                    <td>0759</td>
                                                    <td>Dropbox</td>
                                                    <td>$40, 672</td>
                                                    <td><span class="badge badge-warning">Waiting</span></td>
                                                </tr>
                                                <tr>
                                                    <td>0741</td>
                                                    <td>Social Media</td>
                                                    <td>$13, 378</td>
                                                    <td><span class="badge badge-info">In Progress</span></td>
                                                </tr>
                                                <tr>
                                                    <td>0740</td>
                                                    <td>Envato Market</td>
                                                    <td>$17, 456</td>
                                                    <td><span class="badge badge-info">In Progress</span></td>
                                                </tr>
                                                <tr>
                                                    <td>0735</td>
                                                    <td>Graphic Design</td>
                                                    <td>$29, 999</td>
                                                    <td><span class="badge badge-secondary">Canceled</span></td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card savings-card">
                                <div class="card-body">
                                    <h5 class="card-title">Savings<span class="card-title-helper">30 Days</span></h5>
                                    <div class="savings-stats">
                                        <h5>$4,502.00</h5>
                                        <span>Total savings for last month</span>
                                    </div>
                                    <div id="sparkline-chart-1"></div>
                                </div>
                            </div>
                            <div class="card top-products">
                                <div class="card-body">
                                    <h5 class="card-title">Popular Products<span class="card-title-helper">Today</span></h5>
                                    <div class="top-products-list">
                                        <div class="product-item">
                                            <h5>Alpha - File Hosting Service</h5>
                                            <span>4,037 downloads</span>
                                            <i class="material-icons product-item-status product-item-success">arrow_upward</i>
                                        </div>
                                        <div class="product-item">
                                            <h5>Lime - Task Managment Dashboard</h5>
                                            <span>1,876 downloads</span>
                                            <i class="material-icons product-item-status product-item-success">arrow_upward</i>
                                        </div>
                                        <div class="product-item">
                                            <h5>Space - Meetup Hosting App</h5>
                                            <span>1,252 downloads</span>
                                            <i class="material-icons product-item-status product-item-danger">arrow_downward</i>
                                        </div>
                                        <div class="product-item">
                                            <h5>Meteor - Messaging App</h5>
                                            <span>938 downloads</span>
                                            <i class="material-icons product-item-status product-item-success">arrow_upward</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="card">
                                <div class="card-header">
                                    <ul class="nav nav-tabs card-header-tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#">Visitors</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Reports</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Investments</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <div class="visitors-stats">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="visitors-stats-info">
                                                    <p>Total visitors in the current period:</p>
                                                    <h5>77,871</h5>
                                                    <span>6-26 Apr</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="visitors-stats-info">
                                                    <p>Unique visitors in the current period and ratio:</p>
                                                    <h5>58,403</h5>
                                                    <span>6-26 Apr</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div id="chart-visitors"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="page-footer">
                <div class="row">
                    <div class="col-md-12">
                        <span class="footer-text">2022 © Creatorium</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Javascripts -->
    <script src="assets/plugins/jquery/jquery-3.4.1.min.js"></script>
    <script src="assets/plugins/bootstrap/popper.min.js"></script>
    <script src="assets/plugins/bootstrap/js/bootstrap.min.js"></script>
    <script src="assets/plugins/jquery-slimscroll/jquery.slimscroll.min.js"></script>
    <script src="assets/plugins/jquery-sparkline/jquery.sparkline.min.js"></script>
    <script src="assets/plugins/apexcharts/dist/apexcharts.min.js"></script>
    <script src="assets/plugins/blockui/jquery.blockUI.js"></script>
    <script src="assets/plugins/flot/jquery.flot.min.js"></script>
    <script src="assets/plugins/flot/jquery.flot.time.min.js"></script>
    <script src="assets/plugins/flot/jquery.flot.symbol.min.js"></script>
    <script src="assets/plugins/flot/jquery.flot.resize.min.js"></script>
    <script src="assets/plugins/flot/jquery.flot.tooltip.min.js"></script>
    <script src="assets/js/connect.min.js"></script>
    <script src="assets/js/pages/dashboard.js"></script>