import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import AppConfig from 'src/app/app-config';
import { LoginRequestModel } from 'src/app/model/entity/auth/login-request-model';
import { CacheService } from '../../framework/cache.service';
import { LoginResponseModel } from 'src/app/model/entity/auth/login-response-model';
import Storage from 'src/app/model/framework/constants/storage';
import { User } from 'src/app/model/entity/auth/user';
import { NotificationService } from '../../framework/notification.service';
import { FastLoginRequestModel } from 'src/app/model/entity/auth/fast-login-request-model';
import { RoutingService } from 'src/app/core/utils/routing.service';
import { ErrorMessageHandler } from 'src/app/core/handler/error-message-handler';
import { AbstractResponse } from 'src/app/model/response/abstract-response';
import { AuthenticateTokenRequestModel } from 'src/app/model/entity/auth/authenticate-token-request-model';
import { Observable } from 'rxjs';
import { RequestStatus } from 'src/app/core/enums/request-status';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  protected url = AppConfig.url;

  constructor(private http: HttpClient, private routing: RoutingService, private errorHandler: ErrorMessageHandler, private cacheService: CacheService, private notificationService: NotificationService) { }
  async register(user: User) {
    try {
      return await this.http.post<LoginResponseModel>(this.url + "/auth/register", user).toPromise();
    } catch (e: any) {
      this.notificationService.showError(e.error, 'Hata!');
      throw e;
    }
  }


  fastSignIn(requestModel: FastLoginRequestModel) {
    return new Promise((resolve) => {
      this.http.post<LoginResponseModel>(this.url + "/authenticate/connect", requestModel)
        .subscribe(
          resp => {
            this.routing.goVerificationWaiting(requestModel);
          },
          (err: HttpErrorResponse) => {
            this.notificationService.showError(err.error, 'Hata!');
          })
    });
  }

  login(requestModel: LoginRequestModel) {
    return new Promise((resolve) => {
      this.http.post<LoginResponseModel>(this.url + "/authenticate/login", requestModel)
        .subscribe(
          resp => {
            this.createSession(resp);
          },
          (err: HttpErrorResponse) => {
            this.notificationService.showError(this.errorHandler.getErrorMessage(err.error), 'Hata!');
          })
    });
  }

  forgotPassword(requestModel: FastLoginRequestModel) {
    return new Promise((resolve) => {
      this.http.post<AbstractResponse<string>>(this.url + "/authenticate/forgotPassword", requestModel)
        .subscribe(
          resp => {
            this.notificationService.showInfo(resp.data, 'Tamam');

          },
          (err: HttpErrorResponse) => {
            this.notificationService.showError(this.errorHandler.getErrorMessage(err.error), 'Hata!');
          })
    });
  }

  authenticate(token: string) {
    const requestModel: AuthenticateTokenRequestModel = new AuthenticateTokenRequestModel(token);
    return new Promise((resolve) => {
      this.http.post<AbstractResponse<LoginResponseModel>>(this.url + "/authenticate/", requestModel)
        .subscribe(
          resp => {
            if (resp.status != RequestStatus.FAILED) {
              this.createSession(resp.data!);
            } else {
              this.notificationService.showError('Hatalı ya da süresi dolmuş token ile işlem yaptınız!', 'Hata!');
              this.routing.go404();
            }
          },
          (err: HttpErrorResponse) => {
            this.notificationService.showError(this.errorHandler.getErrorMessage(err.error), 'Hata!');
          })
    });
  }


  async createSession(loginResponseModel: LoginResponseModel) {
    this.cacheService.setLocal(Storage.SESSION_TYPE, Storage.SESSION_STORAGE);
    this.cacheService.storage_type = Storage.SESSION_STORAGE;
    sessionStorage.setItem(Storage.TOKEN, JSON.stringify(loginResponseModel.token));
    sessionStorage.setItem(Storage.USER, JSON.stringify(loginResponseModel.user));
    sessionStorage.setItem(Storage.SESSION_TYPE, JSON.stringify(Storage.SESSION_STORAGE));
    this.routing.goHome();
  }


  async logOut() {
    await this.http.get<any>(this.url + "/auth/logout/").toPromise().then(resp => {
      localStorage.clear();
      sessionStorage.clear();
      this.routing.goSignIn();
    },
      (err: HttpErrorResponse) => {
        localStorage.clear();
        sessionStorage.clear();
        this.routing.goSignIn();
      });
  }

  currentUser(): User {
    return this.cacheService.get(Storage.USER);
  }

  isLoggedIn(): boolean {
    return (localStorage.getItem(Storage.TOKEN) != null || sessionStorage.getItem(Storage.TOKEN) != null);
  }
}
